<template>
  <div class="about" >
    <div class="d-flex flex-column min-vh-100">
    <Menubar msg="test" @query="query" @getwinsize="getwinsize" ref="MyBar" />
      <ul>
        <li>
          <div class="mt-3" >
            <Orginforject v-if="uId!=='' && JSON.stringify(objs)!=='{}'" :obj="objs" :oid="uId" :winsize="windowsize" />
          </div>
        </li>
      </ul>
    <div class="wrapper flex-grow-1"></div>      
    <Efootbar msg="test" /> 
    </div>
  </div>
</template>
<script>
//import { macService } from '../_services'
import { mapState } from "vuex";
import * as moment from "moment/moment";
import Orginforject from './panel/Orginforject.vue'
import Menubar from '@/components/Menubar.vue'
import Efootbar from '@/components/Efootbar.vue'

/*const queryData = params => {
  return macService.macfind(params.id) 
};*/


export default {
  name: 'Myorg',
  components: {
    Menubar,
    Efootbar,
    Orginforject,
  }, 
  props: {
    msg: String
  },
  data() {
    return {
      classtheme:null,
      title:{title:'主辦單位',logo:"project"},
      windowsize: {
            width: 0,
            height: 0
        },        
      objs: {},      
      uId:'',
    };
  },  
  mounted(){
        //console.log(this.$route.query.userId);
    this.uId = this.defaultval.id  
    //this.fetch({id:this.uId});
    this.objs=this.datas.find(item => item.id == this.defaultval.id)
    this.$refs["MyBar"].closebar(false); 
  },
  computed: {
    ...mapState("account", ["status", "user"]), 
    ...mapState("makeorg", ["datas","defaultval"]),
  },  
  methods: {
    /*fetch(params = {}) {
      queryData({
        ...params,
      }).then(({ data }) => {
          this.objs = data;
      });
    },*/
    query(rs){
      this.classtheme = rs
    },  
    getwinsize(val){
      this.windowsize.width = val.width
      this.windowsize.height = val.height
    },       
    fdateformat(val){
        return moment(val).format("YYYY-MM-DD HH:mm"); 
    }
  },
  
}
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>

